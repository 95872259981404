$primary: #2596be;
$primarylight: #028e37;
$primarylighter: #02bc6b;
$secondary: #1a626b;
$accent: #ff9900;
$bg: #f6fafd;
$line: #e4e8eb;
$dark: #8d0000;
$darkComplimentary: #c192f0;
$bg1: #F0F4F9;
$bg2: #E6ECF1;
$lightPrimary:#2596be;
$green: #00b32a;
