.billing {
  .integration {
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    z-index: 999;
    padding: 1em 2em 1em 2em;
    margin: auto;
    box-shadow: 2px 2px 2px 2px black;
    margin-top: 4em;

    .wrapper {
      text-align: center;

      .results {
        padding-top: 1em;
        display: grid;
        grid-template-columns: repeat(2, auto);
        h3 {
          font-weight: normal;
        }
      }
    }
  }
}
